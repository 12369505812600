.skills div {
	margin-bottom: 0.8rem;
}
.skills .inline {
	justify-content: space-between;
}
.skill__container {
	margin-bottom: 3.2rem;
}
.skill__logo-container {
	display:flex;
	gap: 16px;
}
.skill__title {
	margin-bottom: 8px;
}
