.avatar {
	width: 150px;
	height: 150px;
	background-size: cover;
	background-position: 0px -40px;
	background-repeat: no-repeat;
	border-radius: 50%;
	border: 3px solid var(--main-color);
	margin: auto;
}
