@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,700;1,100&display=swap');

:root {
	--main-color: #002fa7;
}
* {
	margin: 0;
	padding: 0;
}
html {
	font-size: 62.5%;
}

body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1.6rem;
	font-weight: 300;
	line-height: 1.6;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
h1 {
	font-size: 5rem;
	line-height: 1.1;
}
h3 {
	font-size: 2rem;
}
h4 {
	font-weight: 300;
}
.inline {
	display: flex;
	align-items: baseline;
}
a {
	color: black;
	border-bottom: 1px solid var(--main-color);
	text-decoration: none;
}
em {
	font-weight: 600;
}
