.contact .icon {
	margin-right: 1.6rem;
	width: 2rem;
}
.contact > div > div {
	margin-bottom: 0.8rem;
}
.contact .v-center {
	align-items: center;
}
