h2 {
	margin: 6.4rem 0 1.6rem;
	line-height: 1;
}
h2:after {
	content: '';
	display: block;
	background-color: var(--main-color);
	width: 3rem;
	height: 3px;
	margin-top: 1.2rem;
}
